// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */

import * as React from "react";
import { createUseScreenVariants } from "@plasmicapp/react-web";

export type LangValue = "ja" | "en";
export const LangContext = React.createContext<LangValue | undefined>(
  "PLEASE_RENDER_INSIDE_PROVIDER" as any
);

export function useLang() {
  return React.useContext(LangContext);
}

export default LangContext;
/* prettier-ignore-end */
