import React from "react";
import { PlasmicRootProvider } from "@plasmicapp/react-web";
import Helmet from "react-helmet";

import { LangContext } from "./src/components/plasmic/blog/PlasmicGlobalVariant__Lang";
import { PageParamsProvider as PageParamsProvider__ } from "@plasmicapp/react-web/lib/host";

export const wrapRootElement = ({ element }) => {
  return <PlasmicRootProvider Head={Helmet}>{element}</PlasmicRootProvider>;
};

export const wrapPageElement = ({
  props: { path, params, location },
  element,
}) => {
  const query = Object.fromEntries(new URLSearchParams(location.search));
  let lang: "ja" | "en" = "ja";
  switch (query.lang) {
    case "en":
      lang = "en";
      break;
    default:
      lang = "ja";
  }
  return (
    <LangContext.Provider value={lang}>
      <PageParamsProvider__ route={path} params={params} query={query}>
        {element}
      </PageParamsProvider__>
    </LangContext.Provider>
  );
};
